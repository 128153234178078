var _on, _on2, _on3, _on4, _on5, _states, _on6, _on7, _states2, _states3, _on8, _playbackStates;

import { Machine } from 'xstate';
var PlaybackEventNames;

(function (PlaybackEventNames) {
  PlaybackEventNames["LOADING"] = "LOADING";
  PlaybackEventNames["LOADED"] = "LOADED";
  PlaybackEventNames["VISIBLE"] = "VISIBLE";
  PlaybackEventNames["INVISIBLE"] = "INVISIBLE";
  PlaybackEventNames["ERROR"] = "ERROR";
  PlaybackEventNames["RETRY"] = "RETRY";
})(PlaybackEventNames || (PlaybackEventNames = {}));

var PlaybackStateNames;

(function (PlaybackStateNames) {
  PlaybackStateNames["Init"] = "init";
  PlaybackStateNames["Loading"] = "loading";
  PlaybackStateNames["Loaded"] = "loaded";
  PlaybackStateNames["Indeterminate"] = "indeterminate";
  PlaybackStateNames["Invisible"] = "invisible";
  PlaybackStateNames["PausingPlayback"] = "pausingPlayback";
  PlaybackStateNames["Paused"] = "paused";
  PlaybackStateNames["Visible"] = "visible";
  PlaybackStateNames["StartingPlayback"] = "startingPlayback";
  PlaybackStateNames["Playing"] = "playing";
  PlaybackStateNames["Error"] = "error";
})(PlaybackStateNames || (PlaybackStateNames = {}));

var PlaybackActionNames;

(function (PlaybackActionNames) {
  PlaybackActionNames["pausePlay"] = "pausePlay";
  PlaybackActionNames["startPlay"] = "startPlay";
})(PlaybackActionNames || (PlaybackActionNames = {}));

var playbackStates = (_playbackStates = {}, _playbackStates[PlaybackStateNames.Init] = {
  on: (_on = {}, _on[PlaybackEventNames.LOADING] = PlaybackStateNames.Loading, _on[PlaybackEventNames.LOADED] = PlaybackStateNames.Loaded, _on[PlaybackEventNames.ERROR] = PlaybackStateNames.Error, _on[PlaybackEventNames.VISIBLE] = "#loaded." + PlaybackStateNames.Visible, _on[PlaybackEventNames.INVISIBLE] = "#loaded." + PlaybackStateNames.Invisible, _on)
}, _playbackStates[PlaybackStateNames.Loading] = {
  on: (_on2 = {}, _on2[PlaybackEventNames.LOADED] = PlaybackStateNames.Loaded, _on2[PlaybackEventNames.ERROR] = PlaybackStateNames.Error, _on2)
}, _playbackStates[PlaybackStateNames.Loaded] = {
  id: 'loaded',
  initial: PlaybackStateNames.Indeterminate,
  states: (_states3 = {}, _states3[PlaybackStateNames.Indeterminate] = {
    on: (_on3 = {}, _on3[PlaybackEventNames.VISIBLE] = PlaybackStateNames.Visible, _on3[PlaybackEventNames.INVISIBLE] = PlaybackStateNames.Invisible, _on3)
  }, _states3[PlaybackStateNames.Invisible] = {
    initial: PlaybackStateNames.PausingPlayback,
    states: (_states = {
      hist: {
        type: 'history',
        history: 'deep'
      }
    }, _states[PlaybackStateNames.PausingPlayback] = {
      on: (_on4 = {}, _on4[PlaybackEventNames.VISIBLE] = "#loaded." + PlaybackStateNames.Visible, _on4),
      invoke: {
        id: 'pause',
        src: PlaybackActionNames.pausePlay,
        onDone: {
          target: PlaybackStateNames.Paused
        },
        onError: {
          target: 'hist'
        }
      }
    }, _states[PlaybackStateNames.Paused] = {
      on: (_on5 = {}, _on5[PlaybackEventNames.VISIBLE] = "#loaded." + PlaybackStateNames.Visible, _on5)
    }, _states)
  }, _states3[PlaybackStateNames.Visible] = {
    initial: PlaybackStateNames.StartingPlayback,
    states: (_states2 = {
      hist: {
        type: 'history',
        history: 'deep'
      }
    }, _states2[PlaybackStateNames.StartingPlayback] = {
      on: (_on6 = {}, _on6[PlaybackEventNames.INVISIBLE] = "#loaded." + PlaybackStateNames.Invisible, _on6),
      invoke: {
        id: 'play',
        src: PlaybackActionNames.startPlay,
        onDone: {
          target: PlaybackStateNames.Playing
        },
        onError: {
          target: 'hist'
        }
      }
    }, _states2[PlaybackStateNames.Playing] = {
      on: (_on7 = {}, _on7[PlaybackEventNames.INVISIBLE] = "#loaded." + PlaybackStateNames.Invisible, _on7)
    }, _states2)
  }, _states3)
}, _playbackStates[PlaybackStateNames.Error] = {
  on: (_on8 = {}, _on8[PlaybackEventNames.RETRY] = PlaybackStateNames.Loading, _on8)
}, _playbackStates);
var inlineVideoStateMachine = Machine({
  id: 'inlineVideo',
  initial: PlaybackStateNames.Init,
  strict: process.env.NODE_ENV === 'development',
  states: playbackStates
}, {});
export { inlineVideoStateMachine, PlaybackEventNames, PlaybackStateNames, PlaybackActionNames };