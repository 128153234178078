var getPriceOptions = function getPriceOptions(t) {
  return [{
    title: t('artwork.prices.all'),
    value: ''
  }, {
    title: t('artwork.prices.low'),
    value: JSON.stringify({
      gt: 0,
      lte: 300
    })
  }, {
    title: t('artwork.prices.medium'),
    value: JSON.stringify({
      gt: 301,
      lt: 1000
    })
  }, {
    title: t('artwork.prices.high'),
    value: JSON.stringify({
      gt: 1001,
      lt: 3000
    })
  }, {
    title: t('artwork.prices.luxury'),
    value: JSON.stringify({
      gt: 3001
    })
  }];
};

export { getPriceOptions };