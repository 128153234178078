var getCategoryOptions = function getCategoryOptions(t) {
  return [{
    title: t('artwork.categories.all'),
    value: ''
  }, {
    title: t('artwork.categories.motion'),
    value: 'MOTION'
  }, {
    title: t('artwork.categories.still'),
    value: 'STILL'
  }];
};

export { getCategoryOptions };