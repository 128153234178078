import { useCallback, useMemo, useState } from 'react';
import { useMutationObserverDirty } from '@/common/hooks/mutation-observer-hook';

function useComputedStyles(ref, pseudoElt) {
  var el = ref.current;

  var _useState = useState(null),
      mutation = _useState[0],
      setMutation = _useState[1];

  var onMutation = useCallback(function (mutationList) {
    setMutation(mutationList);
  }, [setMutation]);
  useMutationObserverDirty(ref, {
    attributes: true,
    subtree: false,
    childList: false
  }, onMutation);
  return useMemo(function () {
    if (typeof window === 'undefined' || !ref.current) {
      return null;
    }

    return window.getComputedStyle(ref.current, pseudoElt); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [el, mutation, pseudoElt]);
}

function useComputedStylesProperty(ref, property, pseudoElt) {
  var styles = useComputedStyles(ref, pseudoElt);
  return useMemo(function () {
    if (!styles) {
      return null;
    }

    return styles.getPropertyValue(property);
  }, [styles, property]);
}

export { useComputedStyles, useComputedStylesProperty };