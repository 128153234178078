import { useCallback, useEffect, useRef, useState } from 'react';

var useMutationObserver = function useMutationObserver(observerConfig, onMutation) {
  var _useState = useState(function () {
    return new MutationObserver(function (mutationsList) {
      onMutation(mutationsList);
    });
  }),
      observer = _useState[0];

  var ref = useCallback(function (node) {
    observer.disconnect();

    if (node) {
      observer.observe(node, observerConfig);
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [observer]);
  return [ref];
};

var useMutationObserverDirty = function useMutationObserverDirty(ref, observerConfig, onMutation) {
  var frame = useRef(0);

  var _useState2 = useState(function () {
    return new MutationObserver(function (mutationsList) {
      cancelAnimationFrame(frame.current);
      frame.current = requestAnimationFrame(function () {
        onMutation(mutationsList);
      });
    });
  }),
      observer = _useState2[0];

  useEffect(function () {
    observer.disconnect();

    if (ref.current) {
      observer.observe(ref.current, observerConfig);
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [ref, observerConfig]);
};

export { useMutationObserver, useMutationObserverDirty };