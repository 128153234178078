import _styled from "@emotion/styled-base";
import React from 'react';
import { jsx as ___EmotionJSX } from "@emotion/core";

var Base = _styled("span", {
  target: "eiqjezp0",
  label: "message_Base"
})(function (props) {
  return props.theme.fonts.text.primary(props);
}, ";color:", function (_ref) {
  var theme = _ref.theme,
      error = _ref.error;
  return error ? theme.colors.themeColors.red : theme.colors.themeColors.gray;
}, ";margin-top:3px;" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL2Zvcm0tZmllbGRzL21lc3NhZ2UudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUc2QyIsImZpbGUiOiIvYnVpbGRzL21vb25hL21vb25hLWZyb250ZW5kL3NyYy9jb21tb24vY29tcG9uZW50cy9mb3JtLWZpZWxkcy9tZXNzYWdlLnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJztcbmltcG9ydCBSZWFjdCwgeyBDU1NQcm9wZXJ0aWVzIH0gZnJvbSAncmVhY3QnO1xuXG5jb25zdCBCYXNlID0gc3R5bGVkLnNwYW48eyBlcnJvcj86IGJvb2xlYW4gfT5gXG4gICR7KHByb3BzKSA9PiBwcm9wcy50aGVtZS5mb250cy50ZXh0LnByaW1hcnkocHJvcHMpfTtcbiAgY29sb3I6ICR7KHsgdGhlbWUsIGVycm9yIH0pID0+XG4gICAgZXJyb3IgPyB0aGVtZS5jb2xvcnMudGhlbWVDb2xvcnMucmVkIDogdGhlbWUuY29sb3JzLnRoZW1lQ29sb3JzLmdyYXl9O1xuICBtYXJnaW4tdG9wOiAzcHg7XG5gO1xuXG5leHBvcnQgaW50ZXJmYWNlIE1lc3NhZ2VQcm9wcyB7XG4gIGVycm9yPzogYm9vbGVhbjtcbiAgc3R5bGU/OiBDU1NQcm9wZXJ0aWVzO1xuICBjbGFzc05hbWU/OiBzdHJpbmc7XG59XG5cbmNvbnN0IE1lc3NhZ2U6IFJlYWN0LkZDPE1lc3NhZ2VQcm9wcz4gPSAoe1xuICBlcnJvcixcbiAgY2hpbGRyZW4sXG4gIHN0eWxlLFxuICBjbGFzc05hbWUsXG59KSA9PiB7XG4gIHJldHVybiAoXG4gICAgPEJhc2Ugc3R5bGU9e3N0eWxlfSBjbGFzc05hbWU9e2NsYXNzTmFtZX0gZXJyb3I9e2Vycm9yfT5cbiAgICAgIHtjaGlsZHJlbn1cbiAgICA8L0Jhc2U+XG4gICk7XG59O1xuXG5leHBvcnQgeyBNZXNzYWdlIH07XG4iXX0= */"));

var Message = function Message(_ref2) {
  var error = _ref2.error,
      children = _ref2.children,
      style = _ref2.style,
      className = _ref2.className;
  return ___EmotionJSX(Base, {
    style: style,
    className: className,
    error: error
  }, children);
};

Message.displayName = "Message";
export { Message };