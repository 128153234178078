import _isFunction from "lodash-es/isFunction";
import _forEach from "lodash-es/forEach";
import { useEffect, useRef } from 'react';

function useCombinedRefs() {
  for (var _len = arguments.length, refs = new Array(_len), _key = 0; _key < _len; _key++) {
    refs[_key] = arguments[_key];
  }

  var targetRef = useRef(null);
  useEffect(function () {
    _forEach(refs, function (ref) {
      if (!ref) {
        return;
      }

      if (_isFunction(ref)) {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);
  return targetRef;
}

export { useCombinedRefs };