var getSortingOptions = function getSortingOptions(t) {
  return [{
    title: t('artwork.sorting.all'),
    value: ''
  }, {
    title: t('artwork.sorting.price_asc'),
    value: JSON.stringify({
      purchasePrice: 'ASC'
    })
  }, {
    title: t('artwork.sorting.price_desc'),
    value: JSON.stringify({
      purchasePrice: 'DESC'
    })
  }, {
    title: t('artwork.sorting.editions_asc'),
    value: JSON.stringify({
      editionSize: 'ASC'
    })
  }, {
    title: t('artwork.sorting.editions_desc'),
    value: JSON.stringify({
      editionSize: 'DESC'
    })
  }, {
    title: t('artwork.sorting.date_desc'),
    value: JSON.stringify({
      curationDateTime: 'DESC'
    })
  }];
};

var getArtistsSortingOptions = function getArtistsSortingOptions(t) {
  return [{
    title: t('artist.sorting.all'),
    value: ''
  }, {
    title: t('artist.sorting.date_asc'),
    value: JSON.stringify({
      activationDateTime: 'ASC'
    })
  }, {
    title: t('artist.sorting.date_desc'),
    value: JSON.stringify({
      activationDateTime: 'DESC'
    })
  }, {
    title: t('artist.sorting.artistName_asc'),
    value: JSON.stringify({
      artistName: 'ASC'
    })
  }, {
    title: t('artist.sorting.artistName_desc'),
    value: JSON.stringify({
      artistName: 'DESC'
    })
  }];
};

var getExhibitionsSortingOptions = function getExhibitionsSortingOptions(t) {
  return [{
    title: t('exhibition.sorting.all'),
    value: ''
  }, {
    title: t('exhibition.sorting.date_asc'),
    value: JSON.stringify({
      activationDateTime: 'ASC'
    })
  }, {
    title: t('exhibition.sorting.date_desc'),
    value: JSON.stringify({
      activationDateTime: 'DESC'
    })
  }, {
    title: t('exhibition.sorting.title_asc'),
    value: JSON.stringify({
      title: 'ASC'
    })
  }, {
    title: t('exhibition.sorting.title_desc'),
    value: JSON.stringify({
      title: 'DESC'
    })
  }];
};

export { getSortingOptions, getArtistsSortingOptions, getExhibitionsSortingOptions };