import "core-js/modules/es.math.hypot.js";
import { useMemo } from 'react';
import useWindowSize from "react-use/esm/useWindowSize";
import useMeasureDirty from 'react-use/lib/useMeasureDirty';
var DistanceAnchor;

(function (DistanceAnchor) {
  DistanceAnchor["Top"] = "top";
  DistanceAnchor["Bottom"] = "bottom";
  DistanceAnchor["Left"] = "left";
  DistanceAnchor["Right"] = "right";
  DistanceAnchor["Center"] = "center";
  DistanceAnchor["North"] = "north";
  DistanceAnchor["East"] = "east";
  DistanceAnchor["South"] = "south";
  DistanceAnchor["West"] = "west";
})(DistanceAnchor || (DistanceAnchor = {}));

var getPositionAtAnchor = function getPositionAtAnchor(element, anchor) {
  var _element$getBoundingC = element.getBoundingClientRect(),
      top = _element$getBoundingC.top,
      left = _element$getBoundingC.left,
      width = _element$getBoundingC.width,
      height = _element$getBoundingC.height;

  var xOffset = 0;
  var yOffset = 0;

  switch (anchor) {
    case DistanceAnchor.Top:
      xOffset = 0;
      yOffset = 0;
      break;

    case DistanceAnchor.Bottom:
      xOffset = 0;
      yOffset = height;
      break;

    case DistanceAnchor.Left:
      xOffset = 0;
      yOffset = 0;
      break;

    case DistanceAnchor.Right:
      xOffset = width;
      yOffset = 0;
      break;

    case DistanceAnchor.Center:
      xOffset = width / 2;
      yOffset = height / 2;
      break;

    case DistanceAnchor.North:
      xOffset = width / 2;
      yOffset = 0;
      break;

    case DistanceAnchor.East:
      xOffset = width;
      yOffset = height / 2;
      break;

    case DistanceAnchor.South:
      xOffset = width / 2;
      yOffset = height;
      break;

    case DistanceAnchor.West:
      xOffset = 0;
      yOffset = height / 2;
      break;
  } // console.log({ top, left, width, height, xOffset, yOffset });


  return {
    x: left + xOffset,
    y: top + yOffset
  };
};

var getDistanceBetweenElementsCenter = function getDistanceBetweenElementsCenter(a, b, aAnchor, bAnchor) {
  var aPosition = getPositionAtAnchor(a, aAnchor);
  var bPosition = getPositionAtAnchor(b, bAnchor);
  return {
    x: bPosition.x - aPosition.x,
    y: bPosition.y - aPosition.y,
    euclidean: Math.hypot(aPosition.x - bPosition.x, aPosition.y - bPosition.y)
  };
};

function useDistance(refA, refB, aAnchor, bAnchor) {
  var a = refA === null || refA === void 0 ? void 0 : refA.current;
  var b = refB === null || refB === void 0 ? void 0 : refB.current;

  var _useWindowSize = useWindowSize(),
      width = _useWindowSize.width,
      height = _useWindowSize.height;

  var aMeasure = useMeasureDirty(refA);
  var bMeasure = useMeasureDirty(refB);
  return useMemo(function () {
    if (!(refA !== null && refA !== void 0 && refA.current) || !(refB !== null && refB !== void 0 && refB.current)) {
      return {};
    }

    return getDistanceBetweenElementsCenter(refA.current, refB.current, aAnchor, bAnchor); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [a, b, aAnchor, bAnchor, width, height, aMeasure, bMeasure]);
}

export { useDistance, DistanceAnchor };