import _styled from "@emotion/styled-base";

var Tab = _styled("button", {
  target: "e1vgw7wv0",
  label: "tab_Tab"
})("background:transparent;border:none;border-bottom:", function (_ref) {
  var active = _ref.active,
      theme = _ref.theme;
  return active ? "3px solid " + theme.colors.text.primary : 'none';
}, ";color:", function (_ref2) {
  var active = _ref2.active,
      theme = _ref2.theme;
  return active ? theme.colors.text.primary : theme.colors.text.muted;
}, ";cursor:pointer;margin-right:1.77rem;padding:0 0 0.5rem;white-space:nowrap;&:focus{outline:none;}&:last-child{margin-right:0;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL3RhYnMvdGFiLnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUU4QyIsImZpbGUiOiIvYnVpbGRzL21vb25hL21vb25hLWZyb250ZW5kL3NyYy9jb21tb24vY29tcG9uZW50cy90YWJzL3RhYi50cyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJztcblxuY29uc3QgVGFiID0gc3R5bGVkLmJ1dHRvbjx7IGFjdGl2ZTogYm9vbGVhbiB9PmBcbiAgYmFja2dyb3VuZDogdHJhbnNwYXJlbnQ7XG4gIGJvcmRlcjogbm9uZTtcbiAgYm9yZGVyLWJvdHRvbTogJHsoeyBhY3RpdmUsIHRoZW1lIH0pID0+XG4gICAgYWN0aXZlID8gYDNweCBzb2xpZCAke3RoZW1lLmNvbG9ycy50ZXh0LnByaW1hcnl9YCA6ICdub25lJ307XG4gIGNvbG9yOiAkeyh7IGFjdGl2ZSwgdGhlbWUgfSkgPT5cbiAgICBhY3RpdmUgPyB0aGVtZS5jb2xvcnMudGV4dC5wcmltYXJ5IDogdGhlbWUuY29sb3JzLnRleHQubXV0ZWR9O1xuICBjdXJzb3I6IHBvaW50ZXI7XG4gIG1hcmdpbi1yaWdodDogMS43N3JlbTtcbiAgcGFkZGluZzogMCAwIDAuNXJlbTtcbiAgd2hpdGUtc3BhY2U6IG5vd3JhcDtcblxuICAmOmZvY3VzIHtcbiAgICBvdXRsaW5lOiBub25lO1xuICB9XG5cbiAgJjpsYXN0LWNoaWxkIHtcbiAgICBtYXJnaW4tcmlnaHQ6IDA7XG4gIH1cbmA7XG5cbmV4cG9ydCB7IFRhYiB9O1xuIl19 */"));

export { Tab };