import _styled from "@emotion/styled-base";
import { mediaquery } from '@/common/utils/styling';

var Tabs = _styled("div", {
  target: "e1vfxldh0",
  label: "tabs_Tabs"
})("border-bottom:", function (_ref) {
  var theme = _ref.theme;
  return "1px solid " + theme.colors.text.muted;
}, ";margin-bottom:2.2rem;margin-left:-1.44rem;overflow-x:auto;padding-left:1.44rem;padding-right:1.44rem;position:relative;white-space:nowrap;width:calc(100% + 2.88rem);", mediaquery.tablet, "{margin-left:0;max-width:100%;padding-left:0;padding-right:0;width:auto;}" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9idWlsZHMvbW9vbmEvbW9vbmEtZnJvbnRlbmQvc3JjL2NvbW1vbi9jb21wb25lbnRzL3RhYnMvdGFicy50cyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJdUIiLCJmaWxlIjoiL2J1aWxkcy9tb29uYS9tb29uYS1mcm9udGVuZC9zcmMvY29tbW9uL2NvbXBvbmVudHMvdGFicy90YWJzLnRzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHN0eWxlZCBmcm9tICdAZW1vdGlvbi9zdHlsZWQnO1xuXG5pbXBvcnQgeyBtZWRpYXF1ZXJ5IH0gZnJvbSAnQC9jb21tb24vdXRpbHMvc3R5bGluZyc7XG5cbmNvbnN0IFRhYnMgPSBzdHlsZWQuZGl2YFxuICBib3JkZXItYm90dG9tOiAkeyh7IHRoZW1lIH0pID0+IGAxcHggc29saWQgJHt0aGVtZS5jb2xvcnMudGV4dC5tdXRlZH1gfTtcbiAgbWFyZ2luLWJvdHRvbTogMi4ycmVtO1xuICBtYXJnaW4tbGVmdDogLTEuNDRyZW07XG4gIG92ZXJmbG93LXg6IGF1dG87XG4gIHBhZGRpbmctbGVmdDogMS40NHJlbTtcbiAgcGFkZGluZy1yaWdodDogMS40NHJlbTtcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICB3aGl0ZS1zcGFjZTogbm93cmFwO1xuICB3aWR0aDogY2FsYygxMDAlICsgMi44OHJlbSk7XG5cbiAgJHttZWRpYXF1ZXJ5LnRhYmxldH0ge1xuICAgIG1hcmdpbi1sZWZ0OiAwO1xuICAgIG1heC13aWR0aDogMTAwJTtcbiAgICBwYWRkaW5nLWxlZnQ6IDA7XG4gICAgcGFkZGluZy1yaWdodDogMDtcbiAgICB3aWR0aDogYXV0bztcbiAgfVxuYDtcblxuZXhwb3J0IHsgVGFicyB9O1xuIl19 */"));

export { Tabs };