import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";

var _templateObject;

/* eslint-disable graphql/template-strings */
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useEffect } from 'react';
import useInterval from "react-use/esm/useInterval";
import { useStoreActions, useStoreState } from '@/common/providers/easy-peasy/hooks';
var etherPriceQuery = gql(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  query EtherPriceQuery {\n    usdPriceInEth: tokenToEthPrice(token: DAI)\n  }\n"])));

function useEther() {
  var storedLastUpdateUsdPriceInEth = useStoreState(function (state) {
    return state.ether.storedLastUpdateUsdPriceInEth;
  });
  var setLastUpdateUsdPriceInEth = useStoreActions(function (actions) {
    return actions.ether.setLastUpdateUsdPriceInEth;
  });
  var storedUsdPriceInEth = useStoreState(function (state) {
    return state.ether.storedUsdPriceInEth;
  });
  var setUsdPriceInEth = useStoreActions(function (actions) {
    return actions.ether.setUsdPriceInEth;
  });

  var _useLazyQuery = useLazyQuery(etherPriceQuery, {
    ssr: false,
    fetchPolicy: 'no-cache',
    onCompleted: function onCompleted() {
      setUsdPriceInEth(etherData === null || etherData === void 0 ? void 0 : etherData.usdPriceInEth);
      setLastUpdateUsdPriceInEth(Date.now());
    }
  }),
      fetchEtherData = _useLazyQuery[0],
      etherData = _useLazyQuery[1].data;

  useEffect(function () {
    if (!storedUsdPriceInEth) {
      fetchEtherData();
    } // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);
  useInterval(function () {
    var _CONFIG, _CONFIG$crypto;

    var refetch = !storedUsdPriceInEth || storedLastUpdateUsdPriceInEth && storedLastUpdateUsdPriceInEth < Date.now() - 1000 * ((_CONFIG = CONFIG) === null || _CONFIG === void 0 ? void 0 : (_CONFIG$crypto = _CONFIG.crypto) === null || _CONFIG$crypto === void 0 ? void 0 : _CONFIG$crypto.ethPriceUpdateIntervalSeconds);
    refetch && fetchEtherData();
  }, 1000 * 60);
  return {
    storedUsdPriceInEth: storedUsdPriceInEth
  };
}

export { useEther };