import { useStoreActions, useStoreState } from '@/common/providers/easy-peasy/hooks';

function useArtworksStore() {
  var storedSearchQuery = useStoreState(function (state) {
    return state.artworks.storedSearchQuery;
  });
  var storedSortQuery = useStoreState(function (state) {
    return state.artworksSort.storedSortQuery;
  });
  var storedReducedFilterSelected = useStoreState(function (state) {
    return state.artworksSort.storedReducedFilterSelected;
  });
  var storedCurrentPage = useStoreState(function (state) {
    return state.artworks.storedCurrentPage;
  });
  var setStoredSearchQuery = useStoreActions(function (actions) {
    return actions.artworks.setStoredSearchQuery;
  });
  var setStoredSortQuery = useStoreActions(function (actions) {
    return actions.artworksSort.setStoredSortQuery;
  });
  var setStoredReducedFilterSelected = useStoreActions(function (actions) {
    return actions.artworksSort.setStoredReducedFilterSelected;
  });
  var setStoredCurrentPage = useStoreActions(function (actions) {
    return actions.artworks.setStoredCurrentPage;
  });
  return {
    storedSearchQuery: storedSearchQuery,
    storedSortQuery: storedSortQuery,
    storedReducedFilterSelected: storedReducedFilterSelected,
    storedCurrentPage: storedCurrentPage,
    setStoredSearchQuery: setStoredSearchQuery,
    setStoredSortQuery: setStoredSortQuery,
    setStoredReducedFilterSelected: setStoredReducedFilterSelected,
    setStoredCurrentPage: setStoredCurrentPage
  };
}

export { useArtworksStore };