var getEditionSizeOptions = function getEditionSizeOptions(t) {
  return [{
    title: t('artwork.editions.all'),
    value: ''
  }, {
    title: t('artwork.editions.singleEdition'),
    value: JSON.stringify({
      gt: 0,
      lte: 1
    })
  }, {
    title: t('artwork.editions.lessThan', {
      count: 10
    }),
    value: JSON.stringify({
      gt: 0,
      lt: 10
    })
  }, {
    title: t('artwork.editions.lessThan', {
      count: 100
    }),
    value: JSON.stringify({
      gt: 0,
      lt: 100
    })
  }, {
    title: t('artwork.editions.lessThan', {
      count: 1000
    }),
    value: JSON.stringify({
      gt: 0,
      lt: 1000
    })
  }, {
    title: t('artwork.editions.lessThan', {
      count: 10000
    }),
    value: JSON.stringify({
      gt: 0,
      lt: 10000
    })
  }, {
    title: t('artwork.editions.moreThan', {
      count: 10000
    }),
    value: JSON.stringify({
      gt: 10000
    })
  }];
};

export { getEditionSizeOptions };